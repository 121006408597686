import jsPDF from "jspdf";
import fapeamLogo from "../main-menu/fapeam.png";
import hineLogo from "../main-menu/hine.png";
import labtamLogo from "../main-menu/labtam.png";
import ufamlogo from "../main-menu/ufam.png";

import moment from "moment";
import autoTable from "jspdf-autotable";

import sec02_motores_chutar01 from "./imgs/sec02_motores_chutar01.png";
import sec02_motores_chutar02 from "./imgs/sec02_motores_chutar02.png";
import sec02_motores_chutar03 from "./imgs/sec02_motores_chutar03.png";
import sec02_motores_engatinhar01 from "./imgs/sec02_motores_engatinhar01.png";
import sec02_motores_engatinhar02 from "./imgs/sec02_motores_engatinhar02.png";
import sec02_motores_engatinhar03 from "./imgs/sec02_motores_engatinhar03.png";
import sec02_motores_engatinhar04 from "./imgs/sec02_motores_engatinhar04.png";
import sec02_motores_sentar01 from "./imgs/sec02_motores_sentar01.png";
import sec02_motores_sentar02 from "./imgs/sec02_motores_sentar02.png";
import sec02_motores_sentar03 from "./imgs/sec02_motores_sentar03.png";
import sec02_motores_sentar04 from "./imgs/sec02_motores_sentar04.png";
import motores_supino_0 from "./imgs/motores_supino_0.png";
import motores_supino_1 from "./imgs/motores_supino_1.png";
import postura_cabeca_0 from "./imgs/postura_cabeca_0.png";
import postura_cabeca_0_0 from "./imgs/postura_cabeca_0_0.png";
import postura_cabeca_0_1 from "./imgs/postura_cabeca_0_1.png";
import postura_cabeca_1 from "./imgs/postura_cabeca_1.png";
import postura_cabeca_1_0 from "./imgs/postura_cabeca_1_0.png";
import postura_cabeca_1_1 from "./imgs/postura_cabeca_1_1.png";
import postura_cabeca_3 from "./imgs/postura_cabeca_3.png";
import postura_supino_0 from "./imgs/postura_supino_0.png";
import postura_supino_1 from "./imgs/postura_supino_1.png";
import postura_supino_3 from "./imgs/postura_supino_3.png";
import postura_tronco_0 from "./imgs/postura_tronco_0.png";
import postura_tronco_1 from "./imgs/postura_tronco_1.png";
import postura_tronco_3 from "./imgs/postura_tronco_3.png";
import postura_tronco_0_1 from "./imgs/postura_tronco_0_1.png";
import postura_tronco_0_2 from "./imgs/postura_tronco_0_2.png";
import postura_tronco_0_3 from "./imgs/postura_tronco_0_3.png";
import reacao_anterior_1 from "./imgs/reacao_anterior_1.png";
import reacao_anterior_3 from "./imgs/reacao_anterior_3.png";
import reacao_braco_0 from "./imgs/reacao_braco_0.png";
import reacao_braco_1 from "./imgs/reacao_braco_1.png";
import reacao_braco_3 from "./imgs/reacao_braco_3.png";
import reacao_lateral_0 from "./imgs/reacao_lateral_0.png";
import reacao_lateral_1 from "./imgs/reacao_lateral_1.png";
import reacao_lateral_2 from "./imgs/reacao_lateral_2.png";
import reacao_lateral_3 from "./imgs/reacao_lateral_3.png";
import reacao_vertical_0 from "./imgs/reacao_vertical_0.png";
import reacao_vertical_1 from "./imgs/reacao_vertical_1.png";
import reacao_vertical_3 from "./imgs/reacao_vertical_3.png";
import tonus_cachecol_0 from "./imgs/tonus_cachecol_0.png";
import tonus_cachecol_1 from "./imgs/tonus_cachecol_1.png";
import tonus_cachecol_3 from "./imgs/tonus_cachecol_3.png";
import tonus_cachecol_0_izquerda from "./imgs/tonus_cachecol_0_izquerda.png";
import tonus_cachecol_0_direita from "./imgs/tonus_cachecol_0_direita.png";
import tonus_cachecol_3_izquerda from "./imgs/tonus_cachecol_3_izquerda.png";
import tonus_cachecol_3_direita from "./imgs/tonus_cachecol_3_direita.png";
import tonus_ombro_0 from "./imgs/tonus_ombro_0.png";
import tonus_ombro_1 from "./imgs/tonus_ombro_1.png";
import tonus_ombro_3 from "./imgs/tonus_ombro_3.png";
import tonus_popliteo_0 from "./imgs/tonus_popliteo_0.png";
import tonus_popliteo_1_0 from "./imgs/tonus_popliteo_1_0.png";
import tonus_popliteo_1_1 from "./imgs/tonus_popliteo_1_1.png";
import tonus_popliteo_2 from "./imgs/tonus_popliteo_2.png";
import tonus_popliteo_3_0 from "./imgs/tonus_popliteo_3_0.png";
import tonus_popliteo_3_1 from "./imgs/tonus_popliteo_3_1.png";
import tonus_quadril_0 from "./imgs/tonus_quadril_0.png";
import tonus_quadril_1 from "./imgs/tonus_quadril_1.png";
import tonus_quadril_2 from "./imgs/tonus_quadril_2.png";
import tonus_quadril_3 from "./imgs/tonus_quadril_3.png";
import tonus_quadril_3_0 from "./imgs/tonus_quadril_3_0.png";
import tonus_quadril_3_1 from "./imgs/tonus_quadril_3_1.png";
import tonus_sentar_0 from "./imgs/tonus_sentar_0.png";
import tonus_sentar_1 from "./imgs/tonus_sentar_1.png";
import tonus_sentar_3 from "./imgs/tonus_sentar_3.png";
import tonus_sentar_3_0 from "./imgs/tonus_sentar_3_0.png";
import tonus_sentar_3_1 from "./imgs/tonus_sentar_3_1.png";
import tonus_tornozelo_0 from "./imgs/tonus_tornozelo_0.png";
import tonus_tornozelo_1_0 from "./imgs/tonus_tornozelo_1_0.png";
import tonus_tornozelo_1_1 from "./imgs/tonus_tornozelo_1_1.png";
import tonus_tornozelo_2 from "./imgs/tonus_tornozelo_2.png";
import tonus_tornozelo_3_0 from "./imgs/tonus_tornozelo_3_0.png";
import tonus_tornozelo_3_1 from "./imgs/tonus_tornozelo_3_1.png";
import tonus_ventral_0 from "./imgs/tonus_ventral_0.png";
import tonus_ventral_1 from "./imgs/tonus_ventral_1.png";
import tonus_ventral_3_0 from "./imgs/tonus_ventral_3_0.png";
import tonus_ventral_3_1 from "./imgs/tonus_ventral_3_1.png";
import "./styles.css";

const imgs = {
    sec02_motores_engatinhar01: sec02_motores_engatinhar01,
    sec02_motores_engatinhar02: sec02_motores_engatinhar02,
    sec02_motores_engatinhar03: sec02_motores_engatinhar03,
    sec02_motores_engatinhar04: sec02_motores_engatinhar04,
    sec02_motores_chutar01: sec02_motores_chutar01,
    sec02_motores_chutar02: sec02_motores_chutar02,
    sec02_motores_chutar03: sec02_motores_chutar03,
    sec02_motores_sentar01: sec02_motores_sentar01,
    sec02_motores_sentar02: sec02_motores_sentar02,
    sec02_motores_sentar03: sec02_motores_sentar03,
    sec02_motores_sentar04: sec02_motores_sentar04,
    motores_supino_0: motores_supino_0,
    motores_supino_1: motores_supino_1,
    postura_cabeca_0: postura_cabeca_0,
    postura_cabeca_0_0: postura_cabeca_0_0,
    postura_cabeca_0_1: postura_cabeca_0_1,
    postura_cabeca_1: postura_cabeca_1,
    postura_cabeca_1_0: postura_cabeca_1_0,
    postura_cabeca_1_1: postura_cabeca_1_1,
    postura_cabeca_3: postura_cabeca_3,
    postura_supino_0: postura_supino_0,
    postura_supino_1: postura_supino_1,
    postura_supino_3: postura_supino_3,
    postura_tronco_0: postura_tronco_0,
    postura_tronco_0_1: postura_tronco_0_1,
    postura_tronco_0_2: postura_tronco_0_2,
    postura_tronco_0_3: postura_tronco_0_3,
    postura_tronco_1: postura_tronco_1,
    postura_tronco_3: postura_tronco_3,
    reacao_anterior_1: reacao_anterior_1,
    reacao_anterior_3: reacao_anterior_3,
    reacao_braco_0: reacao_braco_0,
    reacao_braco_1: reacao_braco_1,
    reacao_braco_3: reacao_braco_3,
    reacao_lateral_0: reacao_lateral_0,
    reacao_lateral_1: reacao_lateral_1,
    reacao_lateral_2: reacao_lateral_2,
    reacao_lateral_3: reacao_lateral_3,
    reacao_vertical_0: reacao_vertical_0,
    reacao_vertical_1: reacao_vertical_1,
    reacao_vertical_3: reacao_vertical_3,
    tonus_cachecol_0: tonus_cachecol_0,
    tonus_cachecol_1: tonus_cachecol_1,
    tonus_cachecol_3: tonus_cachecol_3,
    tonus_cachecol_0_direita: tonus_cachecol_0_direita,
    tonus_cachecol_0_izquerda: tonus_cachecol_0_izquerda,
    tonus_cachecol_3_direita: tonus_cachecol_3_direita,
    tonus_cachecol_3_izquerda: tonus_cachecol_3_izquerda,
    tonus_ombro_0: tonus_ombro_0,
    tonus_ombro_1: tonus_ombro_1,
    tonus_ombro_3: tonus_ombro_3,
    tonus_popliteo_0: tonus_popliteo_0,
    tonus_popliteo_1_0: tonus_popliteo_1_0,
    tonus_popliteo_1_1: tonus_popliteo_1_1,
    tonus_popliteo_2: tonus_popliteo_2,
    tonus_popliteo_3_0: tonus_popliteo_3_0,
    tonus_popliteo_3_1: tonus_popliteo_3_1,
    tonus_quadril_0: tonus_quadril_0,
    tonus_quadril_1: tonus_quadril_1,
    tonus_quadril_2: tonus_quadril_2,
    tonus_quadril_3: tonus_quadril_3,
    tonus_quadril_3_0: tonus_quadril_3_0,
    tonus_quadril_3_1: tonus_quadril_3_1,
    tonus_sentar_0: tonus_sentar_0,
    tonus_sentar_1: tonus_sentar_1,
    tonus_sentar_3: tonus_sentar_3,
    tonus_sentar_3_0: tonus_sentar_3_0,
    tonus_sentar_3_1: tonus_sentar_3_1,
    tonus_tornozelo_0: tonus_tornozelo_0,
    tonus_tornozelo_1_0: tonus_tornozelo_1_0,
    tonus_tornozelo_1_1: tonus_tornozelo_1_1,
    tonus_tornozelo_2: tonus_tornozelo_2,
    tonus_tornozelo_3_0: tonus_tornozelo_3_0,
    tonus_tornozelo_3_1: tonus_tornozelo_3_1,
    tonus_ventral_0: tonus_ventral_0,
    tonus_ventral_1: tonus_ventral_1,
    tonus_ventral_3_0: tonus_ventral_3_0,
    tonus_ventral_3_1: tonus_ventral_3_1,
};

export default function createAvaliacaoPdf(
    name,
    patientData,
    hineData,
    recordDate,
    doctorData
) {
    const doc = new jsPDF("p", "pt", "a4", false);
    doc.setFontSize(10);

    const hineImage = { src: hineLogo, w: 850, h: 124 };
    const ufamImage = { src: ufamlogo, w: 121, h: 126 };
    const fapeamImage = { src: fapeamLogo, w: 299, h: 86 };
    const labtamImage = { src: labtamLogo, w: 290, h: 133 };

    const headerHeight = setCabecalho(
        doc,
        ["l", "r", "r", "r"],
        5,
        50,
        hineImage,
        ufamImage,
        fapeamImage,
        labtamImage
    );

    const patientDataArray = [
        ["Nome", patientData.name, "CNS", "xxx xxxx xxxx xxxx"],
        [
            "Data de Nascimento",
            moment(patientData.birthDate).format("YYYY-MM-DD"),
            "Sexo",
            patientData.sex,
        ],
        [
            "Semanas de gestação",
            patientData?.idadeGestacional || "Desconhecido",
            "",
            "",
        ],
        [
            "Idade corrigida",
            patientData.idadeCorrigida,
            "Idade cronológica",
            patientData.idadeCronologica,
        ],
        [
            "Data de Avaliação",
            moment(recordDate).format("YYYY-MM-DD HH:MM"),
            "",
            "",
        ],
        [
            "Avaliador(a)",
            doctorData.name,
            "Email do avaliador(a)",
            doctorData.email,
        ],
    ];
    const columnWidths = [120, 160, 120, 155];
    const startY = headerHeight + 10;
    const endTableY =
        generateTable(doc, startY, patientDataArray, columnWidths, false) + 20;

    const section1 = hineData.hine[0]; // Section 1
    const totalPoints = section1.subsections.reduce(
        (acc, sub) => acc + (sub.points || 0),
        0
    );

    doc.text(`Seção 1 - Pontuação Total: ${totalPoints} pts`, 20, endTableY);

    const subsectionSummaries = section1.subsections.map((sub) => [
        sub.title,
        sub.points ? `${sub.points} pts` : "Não pontuado",
    ]);
    autoTable(doc, {
        startY: endTableY + 10,
        head: [["Subseção", "Pontuação"]],
        body: subsectionSummaries,
        margin: { left: 20 }, // Set left margin to align with the left edge
    });

    let currentY = doc.lastAutoTable.finalY + 20;
    doc.text("Detalhamento das Questões:", 20, currentY);
    currentY += 10;
    console.log(section1);

    section1.subsections.forEach((subsection) => {
        const tableData = [];

        subsection.topics.forEach((topic) => {
            let answerText = "";
            let scoreText = "";
            let imgPath = null;
            let assText = "";
            console.log("Topico ", topic);

            if (topic.selectedScore && imgs[topic.selectedScore]) {
                const selectedScore = topic.scores.find(
                    (score) => score.id === topic.selectedScore
                );
                answerText = selectedScore?.title?.split('<br>').join(' ') || "-";
                scoreText = `${selectedScore?.score} pts`;
                imgPath = imgs[topic.selectedScore];
                assText = topic.ass === true ? 'SIM' : 'Sem assimetria';
            } else if (
                topic.selectedScoreDireita &&
                topic.selectedScoreIzquerda
            ) {
                const leftScore = topic.scores.find(
                    (score) => score.idIzquerda === topic.selectedScoreIzquerda
                );
                const rightScore = topic.scores.find(
                    (score) => score.idDireita === topic.selectedScoreDireita
                );
                const combinedScore = topic.scores.find(
                    (score) => score.id === topic.selectedScore
                );

                const leftText =
                    leftScore?.title?.split("<br>").join(" ") ||
                    "Não encontrado";
                const rightText =
                    rightScore?.title?.split("<br>").join(" ") ||
                    "Não encontrado";
                answerText = `Esquerda: ${leftText}\nDireita: ${rightText}`;
                scoreText = `E: ${topic?.reduceFactorIzq || 0} pts / D: ${
                    topic?.reduceFactorDir || 0
                  } pts`;
                assText = topic.ass === true ? "SIM" : "Sem assimetria";
            } else if (topic.selectedScore) {
                const selectedScore = topic.scores.find(
                    (score) => score.id === topic.selectedScore
                );
                answerText =
                    selectedScore?.title?.split("<br>").join(" ") ||
                    "-";
                scoreText = `${selectedScore?.score} pts`;
                assText = topic.ass === true ? "SIM" : "Sem assimetria";
            } else {
                answerText = "Resposta não encontrada!";
                scoreText = "0 pts";
            }

            tableData.push([
                subsection.title,
                topic.title,
                answerText,
                scoreText,
                assText, // Will be handled in didDrawCell
            ]);
        });

        // Create the table for this subsection
        autoTable(doc, {
            startY: currentY,
            head: [["Subseção", "Tópico", "Resposta", "Escore", "Assimetria"]],
            body: tableData,
            columnStyles: {
                0: { cellWidth: 80 },
                1: { cellWidth: 100 },
                2: {
                    cellWidth: 180,
                    cellPadding: 8,
                    fontSize: 10,
                    overflow: "linebreak",
                    halign: "left",
                },
                3: {
                    cellWidth: 70,
                    halign: "center",
                },
                4: {
                    cellWidth: 80
                },
            },
            didDrawCell: function (data) {
                // Handle image insertion
                if (data.column.index === 4 && data.cell.raw === "SIM") {
                    doc.setFillColor(255, 200, 200);
                    doc.rect(
                        data.cell.x,
                        data.cell.y,
                        data.cell.width,
                        data.cell.height,
                        "F"
                    );

                    doc.setTextColor(0, 0, 0); 
                    //doc.setFont("helvetica", "bold"); 

                    const textX = data.cell.x + data.cell.width / 2;
                    const textY = data.cell.y + data.cell.height / 2;

                    doc.text("SIM", textX, textY, {
                        align: "center",
                        baseline: "middle",
                    });
                }
                if (data.column.index === 5 && data.cell.raw) {
                    const imgPath = data.cell.raw;
                    /*if (imgPath) {
                        const imgWidth = 60;
                        const imgHeight = 60;
                        const cellX = data.cell.x + 10;
                        const cellY = data.cell.y + 5;
                        doc.addImage(
                            imgPath,
                            "PNG",
                            cellX,
                            cellY,
                            imgWidth,
                            imgHeight
                        );
                    }*/
                }
            },
            margin: { top: 20, right: 20, bottom: 20, left: 20 },
            headStyles: {
                fillColor: [41, 128, 185],
                textColor: 255,
                fontStyle: "bold",
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245],
            },
            styles: {
                overflow: "linebreak",
                cellPadding: 5,
                fontSize: 10,
                lineColor: [189, 195, 199],
                lineWidth: 0.5,
                minCellHeight: 70, // Ensure enough space for images and wrapped text
            },
            rowPageBreak: "auto",
            bodyStyles: {
                valign: "middle",
            },
        });

        currentY = doc.lastAutoTable.finalY + 20;

        if (currentY > doc.internal.pageSize.height - 50) {
            doc.addPage();
            currentY = 40;
        }
    });

    doc.save(`${name}.pdf`);
}

const setCabecalho = (doc, directions, gap, centerGap, ...images) => {
    let cursorLeft = 10;
    let cursorRight = 600 - 10;
    let cursorUpper = 10;
    let accWidth = 600 - centerGap - 20;
    const multiplicador =
        accWidth / images.reduce((acc, img) => acc + img.w, 0);

    let heightMaior = 0;
    images.forEach((image, index) => {
        const newWidth = image.w * multiplicador;
        const newHeight = newWidth * (image.h / image.w);

        if (heightMaior < newHeight) {
            heightMaior = newHeight;
        }

        if (directions[index] === "l") {
            doc.addImage(
                image.src,
                "PNG",
                cursorLeft,
                cursorUpper,
                newWidth,
                newHeight
            );
            cursorLeft += newWidth + gap;
        } else {
            cursorRight -= newWidth + gap;
            doc.addImage(
                image.src,
                "PNG",
                cursorRight,
                cursorUpper,
                newWidth,
                newHeight
            );
        }
    });

    return cursorUpper + heightMaior + 10;
};

const generateTable = (doc, startY, data, columnWidths, centerAlign) => {
    let y = startY;
    //const rowStyle = { fillColor: "#f2f2f2" };
    data.forEach((row) => {
        let x = 20;
        row.forEach((cell, index) => {
            const cellText = typeof cell === "string" ? cell : `${cell}`;
            const textX = centerAlign
                ? x + columnWidths[index] / 2 - cellText.length * 2
                : x + 5;
            //doc.setFillColor(rowStyle.fillColor);
            doc.rect(x, y, columnWidths[index], 15);
            doc.text(cellText, textX, y + 10);
            x += columnWidths[index];
        });
        y += 20;
    });
    return y;
};
