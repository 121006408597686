import React, { useState, useEffect } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import PageLayout from "examples/LayoutContainers/PageLayout";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import { useSelector } from "react-redux";

import env from "react-dotenv";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Button } from "@mui/material";
import moment from "moment";
import createPdf from "./generatePdf";
import createAvaliacaoPdf from "./generateAvaliacaoPdf";

function RecordsMain() {
  const [recordsByPatient, setRecordByPatient] = useState({});
  const authData = useSelector((state) => state.auth.data);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData.logedIn) {
      navigate("/");
    }
  }, [authData, navigate]);

  useEffect(() => {
    const recordUrl = `${env.API_URL}/admin/record/getByDoctor/${authData.userId}?secret_token=${authData.token}`;
    axios.get(recordUrl).then((resp) => {
      const filtered = resp.data.filter((rep) => rep.patient !== undefined);

      const recordsData = filtered.reduce((group, report) => {
        const patient = report.patient;

        group[patient._id] = group[patient._id] ?? [];

        group[patient._id].push({ ...report });
        return group;
      }, {});

      setRecordByPatient(recordsData);
    });
  }, [authData.token, authData.userId]);

  return (
    <PageLayout>
      <MDBox fullWidth>
        <DefaultNavbar
          action={{
            type: "external",
            route: "https://google.com",
            label: "free download",
            color: "dark",
          }}
          light={false}
        />
      </MDBox>
      <MDBox sx={{ 
          margin: "10px",
       }} fullWidth>
        <Link to="/">
          <MDButton
            color="info"
            fullWidth
          >
            Voltar a Menu Inicial
        </MDButton>
      </Link>
      </MDBox>
      
      <MDBox sx={{ padding: "10px" }}>
        <Card fullWidth>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Relatorios
            </MDTypography>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ mt: 1, mb: 2 }}
            ></Grid>

            <MDBox fullWidth>
              {Object.keys(recordsByPatient).map((patientId) => {
                const recordData = recordsByPatient[patientId];

                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="subtitle1">
                        <b>{recordData[0].patient.name}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexDirection: "column",
                        }}
                      >
                        {recordData.map((recordK) => {
                          console.log(recordData);
                          return (
                            <div style={{ display: "flex", gap: 2 }}>
                              <span style={{ marginRight: "auto" }}>
                                {moment(recordK.date).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </span>
                              <Button
                                variant="contained"
                                style={{color: "white"}}
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() =>
                                  createAvaliacaoPdf(
                                    `${recordK.patient.name}_${recordK.date}`,
                                    recordK.patient,
                                    JSON.parse(recordK.data),
                                    recordK.date,
                                    recordK.doctor
                                  )
                                }
                              >
                                Imprimir Avaliação
                              </Button>
                              <Button
                                variant="contained"
                                style={{color: "white"}}
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() =>
                                  createPdf(
                                    `${recordK.patient.name}_${recordK.date}`,
                                    recordK.patient,
                                    JSON.parse(recordK.data),
                                    recordK.date,
                                    recordK.doctor
                                  )
                                }
                              >
                                Exportar PDF
                              </Button>
                            </div>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default RecordsMain;
